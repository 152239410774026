import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify'
import '../App.css';
import {Auth} from 'aws-amplify';

// import helmetGreenBay from '../assets/helmet-greenbay.png';
// import helmetSanFran from '../assets/helmet-sanfran.png';
// import helmetTenessee from '../assets/helmet-tenessee.png';
// import helmetCinncinati from '../assets/helmet-cinncinati.png';
// import helmetBrowns from '../assets/helmet-browns.png';
// import helmetDetroit from '../assets/helmet-detroit.png';
// import helmetJacksonville from '../assets/helmet-jasksonville.png';
// import helmetRams from '../assets/helmet-rams.png';
// import helmetMinnesota from '../assets/helmet-minnesota.png';
// import helmetBears from '../assets/helmet-bears.png';
// import helmetBucs from '../assets/helmet-bucs.png';
// import helmetBuffalo from '../assets/helmet-buffalo.png';
// import helmetCards from '../assets/helmet-cards.png';
// import helmetChargers from '../assets/helmet-chargers.png';
// import helmetDallas from '../assets/helmet-dallas.png';
// import helmetPhilly from '../assets/helmet-philly.png';
// import helmetRaiders from '../assets/helmet-raiders.png';
// import helmetSeahawks from '../assets/helmet-seahawks.png';
// import helmetTexans from '../assets/helmet-texans.png';
// import helmetColts from '../assets/helmet-colts.png';
// import helmetDenver from '../assets/helmet-denver.png';
// import helmetFalcons from '../assets/helmet-falcons.png';
// import helmetGiants from '../assets/helmet-giants.png';
// import helmetJets from '../assets/helmet-jets.png';
// import helmetKC from '../assets/helmet-kc.png';
// import helmetMiami from '../assets/helmet-miami.png';
// import helmetPanthers from '../assets/helmet-panthers.png';
// import helmetPats from '../assets/helmet-pats.png';
// import helmetRavens from '../assets/helmet-ravens.png';
// import helmetSteelers from '../assets/helmet-steelers.png';
// import helmetSaints from '../assets/helmet-saints.png';
// import helmetWashington from '../assets/helmet-washington.png';

function AddWager(){

    const [linePickedTeam, setLinePickedTeam] = useState('')
    const [opposingTeamName,setOpposingTeamName] = useState('')
    const [pickUserID,setPickUserID] = useState(1)
    const [pickedTeamName, setPickedTeamName] = useState('')
    const [pickYear, setPickYear] = useState(2024)
    const [scoreOpposingTeam, setScoreOpposingTeam] = useState(0)
    const [scorePickedTeam, setScorePickedTeam] = useState(0)
    const [seasonWeek, setSeasonWeek] = useState(0)
    const [Wager, setWager] = useState(20)
    const [user, setUser] = useState('')
    
    function playerMenu() {
        return (
            <select onChange={e => setPickUserID(e.currentTarget.value)}>
            <option value="1">Mark</option>
            <option value="2">Bill</option>
            </select>
        );
    }

    function teamMenu1() {
        return (
            <select onChange={e => setPickedTeamName(e.currentTarget.value)}>
            <option value="PickedTeam">PickedTeam</option>    
            <option value="AtlantaFalcons">AtlantaFalcons</option>
            <option value="ArizonaCardinals">ArizonaCardinals</option>
            <option value="BaltimoreRavens">BaltimoreRavens</option>
            <option value="BuffaloBills">BuffaloBills</option>
            <option value="CarolinaPanthers">CarolinaPanthers</option>
            <option value="ChicagoBears">ChicagoBears</option>
            <option value="CincinnatiBengals">CincinnatiBengals</option>
            <option value="ClevelandBrowns">ClevelandBrowns</option>
            <option value="DallasCowboys">DallasCowboys</option>
            <option value="DenverBroncos">DenverBroncos</option>
            <option value="DetroitLions">DetroitLions</option>
            <option value="GreenBayPackers">GreenBayPackers</option>
            <option value="HoustonTexans">HoustonTexans</option>
            <option value="IndianapolisColts">IndianapolisColts</option> 
            <option value="JacksonvilleJaguars">JacksonvilleJaguars</option>
            <option value="KansasCityChiefs">KansasCityChiefs</option>
            <option value="LosAngelesRams">LosAngelesRams</option>
            <option value="MiamiDolphins">MiamiDolphins</option>
            <option value="MinnesottaVikings">MinnesottaVikings</option>
            <option value="NewEnglandPatriots">NewEnglandPatriots</option>
            <option value="NewOrleansSaints">NewOrleansSaints</option>
            <option value="NewYorkGiants">NewYorkGiants</option>
            <option value="NewYorkJets">NewYorkJets</option>
            <option value="OaklandRaiders">OaklandRaiders</option>
            <option value="PhiladelphiaEagles">PhiladelphiaEagles</option>
            <option value="PittsburghSteelers">PittsburghSteelers</option>
            <option value="SaintLouisRams">SaintLouisRams</option>>
            <option value="SanDiegoChargers">SanDiegoChargers</option>
            <option value="SanFrancisco49ers">SanFrancisco49ers</option>
            <option value="SeattleSeahawks">SeattleSeahawks</option>
            <option value="TampaBayBuccaneers">TampaBayBuccaneers</option>
            <option value="TennesseeTitans">TennesseeTitans</option>
            <option value="WashingtonRedskins">WashingtonRedskins</option>
            
            </select>
        );
    }

    function teamMenu2() {
        return (
            <select onChange={e => setOpposingTeamName(e.currentTarget.value)}>
            <option value="OpposingTeam">OpposingTeam</option>   
            <option value="AtlantaFalcons">AtlantaFalcons</option>
            <option value="ArizonaCardinals">ArizonaCardinals</option>
            <option value="BaltimoreRavens">BaltimoreRavens</option>
            <option value="BuffaloBills">BuffaloBills</option>
            <option value="CarolinaPanthers">CarolinaPanthers</option>
            <option value="ChicagoBears">ChicagoBears</option>
            <option value="CincinnatiBengals">CincinnatiBengals</option>
            <option value="ClevelandBrowns">ClevelandBrowns</option>
            <option value="DallasCowboys">DallasCowboys</option>
            <option value="DenverBroncos">DenverBroncos</option>
            <option value="DetroitLions">DetroitLions</option>
            <option value="GreenBayPackers">GreenBayPackers</option>
            <option value="HoustonTexans">HoustonTexans</option>
            <option value="IndianapolisColts">IndianapolisColts</option> 
            <option value="JacksonvilleJaguars">JacksonvilleJaguars</option>
            <option value="KansasCityChiefs">KansasCityChiefs</option>
            <option value="LosAngelesRams">LosAngelesRams</option>
            <option value="MiamiDolphins">MiamiDolphins</option>
            <option value="MinnesottaVikings">MinnesottaVikings</option>
            <option value="NewEnglandPatriots">NewEnglandPatriots</option>
            <option value="NewOrleansSaints">NewOrleansSaints</option>
            <option value="NewYorkGiants">NewYorkGiants</option>
            <option value="NewYorkJets">NewYorkJets</option>
            <option value="OaklandRaiders">OaklandRaiders</option>
            <option value="PhiladelphiaEagles">PhiladelphiaEagles</option>
            <option value="PittsburghSteelers">PittsburghSteelers</option>
            <option value="SaintLouisRams">SaintLouisRams</option>>
            <option value="SanDiegoChargers">SanDiegoChargers</option>
            <option value="SanFrancisco49ers">SanFrancisco49ers</option>
            <option value="SeattleSeahawks">SeattleSeahawks</option>
            <option value="TampaBayBuccaneers">TampaBayBuccaneers</option>
            <option value="TennesseeTitans">TennesseeTitans</option>
            <option value="WashingtonRedskins">WashingtonRedskins</option>
            </select>
        );
    }

    async function checkUser() {
            let user = await Auth.currentAuthenticatedUser();  
            console.log("USER", user.username)
            setUser(user.username)
        }
   

    async function createWager() {
        let myInit = {
            body:{
               "Action": "createWager",
               "line_picked_team": linePickedTeam,
               "opposing_team_name": opposingTeamName,
               "pick_user_id": pickUserID,
               "picked_team_name": pickedTeamName,
               "pk": pickYear,
               "score_opposing_team": scoreOpposingTeam,
               "score_picked_team": scorePickedTeam,
               "season_week": seasonWeek,
               "wager": Wager,
            }
        }
        const apiData = await API.post ('buckluckyapi1', '/picks3', myInit)
        console.log("RESPONSE",apiData)
    }

    checkUser();

    useEffect(() => {
 
    },[])

    if(user === 'mark'){
        return (
        <div>
            <h2>{pickYear} Season</h2>
            <p><button className="butt1" type="button" onClick={() => createWager()} >Post Wager</button></p>
            <table className="gridwager">
                <tr><td>Player:</td><td className="gridwagerleft">{playerMenu()}</td></tr>
                <tr><td>PickedTeam:</td><td className="gridwagerleft">{teamMenu1()}</td></tr>
                <tr><td>Line:</td><td className="gridwagerleft"><input type="text" value={linePickedTeam} onChange={event => setLinePickedTeam(event.target.value)} /></td></tr>
                <tr><td>OpposingTeam:</td><td className="gridwagerleft">{teamMenu2()}</td></tr>
                <tr><td>SeasonWeek:</td><td className="gridwagerleft"><input type="text" value={seasonWeek} onChange={event => setSeasonWeek(event.target.value)} /></td></tr>
                <tr><td>Wager:</td><td className="gridwagerleft"><input type="text" value={Wager} onChange={event => setWager(event.target.value)} /></td></tr>
                <tr><td>ScorePickedTeam:</td><td className="gridwagerleft"><input type="text" value={scorePickedTeam} onChange={event => setScorePickedTeam(event.target.value)} /></td></tr>
                <tr><td>ScoreOpposingTeam:</td><td className="gridwagerleft"><input type="text" value={scoreOpposingTeam} onChange={event => setScoreOpposingTeam(event.target.value)} /></td></tr>
                <tr><td>PickYear:</td><td className="gridwagerleft"><input type="text" value={pickYear} onChange={event => setPickYear(event.target.value)} /></td></tr>
            </table>  
                
        </div>
        )
    }else{
        return(
            <div className="content">
            <div>
                <p>You need authorization to add wagers.</p>
            </div>
            </div>
        )

    }

}
export default AddWager