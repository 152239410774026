import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify'
import '../App.css';
import picMac from '../assets/billy-simpsons.png';
import picMark from '../assets/mark-simpsons.png';
import picMacW from '../assets/barney-king3.png';
import picMarkL from '../assets/mark-loser1.png';

function AllTimeStats() {

        const dataYear = 2000
        const [picks2, setPicks2] = useState([])

        useEffect(() => {
                async function getFootball() {
                     let myInit = {
                         body:{
                             "Action": "getAllTimeBets",
                             "year": Number(dataYear)
                         }
                     }
                     const apiData = await API.post ('buckluckyapi1', '/picks3', myInit)
                     console.log("RESPONSE",apiData)
                     console.log("YESR",dataYear)
                     setPicks2(apiData)
                }
                getFootball()
            },[])
            
            let winPicksMark = 0
            let losePicksMark = 0
            let tiePicksMark = 0
            let winPicksMac = 0
            let losePicksMac = 0
            let tiePicksMac = 0
            let winningsMark = 320
            let winningsMac = -320
            picks2.forEach( pick => {
                if(pick.pick_user_id === 1){
                        if((Number(pick.score_picked_team) + (Number(pick.line_picked_team))) > pick.score_opposing_team){
                                winPicksMark = winPicksMark + 1
                                winningsMark = Number(winningsMark) + Number(pick.wager)
                                winningsMac = Number(winningsMac) - Number(pick.wager)
                        }else if((Number(pick.score_picked_team) + (Number(pick.line_picked_team))) < pick.score_opposing_team){
                                losePicksMark = losePicksMark + 1
                                winningsMark = Number(winningsMark) - Number(pick.wager)
                                winningsMac = Number(winningsMac) + Number(pick.wager)
                        }else{
                                tiePicksMark = tiePicksMark + 1
                        }
                }
                if(pick.pick_user_id === 2){
                        if((Number(pick.score_picked_team) + (Number(pick.line_picked_team))) > pick.score_opposing_team){
                                winPicksMac = winPicksMac + 1
                                winningsMark = Number(winningsMark) - Number(pick.wager)
                                winningsMac = Number(winningsMac) + Number(pick.wager)
                        }else if((Number(pick.score_picked_team) + (Number(pick.line_picked_team))) < pick.score_opposing_team){
                                losePicksMac = losePicksMac + 1
                                winningsMark = Number(winningsMark) + Number(pick.wager)
                                winningsMac = Number(winningsMac) - Number(pick.wager)
                        }else{
                                tiePicksMac = tiePicksMac + 1
                        }
                }
            })
            let winPercentMark = (Math.round(winPicksMark / (winPicksMark + losePicksMark) * 10000)/100).toFixed(2)
            let winPercentMac = (Math.round(winPicksMac / (winPicksMac + losePicksMac) * 10000)/100).toFixed(2)
            console.log("MARK",winPercentMark)
            console.log("MAC",winPercentMac)
        if(winningsMark >= winningsMac){
            return( 
            <div className="content">
                    <h2>LifeTime Stats</h2>
            <div className="voice1">
            
            <div className="playercontainer">
                    <div className="playerdiv">
                    <img src={picMark} alt="Player" className="playerPic" />
                    </div>
                    <div className="playerdivstats">
                    <span className="statbold">Player Stats:</span><br />
                    <span className="statbold">Mark {winPicksMark}-{losePicksMark}-{tiePicksMark}</span><br />
                    AllTime Pick Winner Percentage: {winPercentMark}%<br />
                    AllTime Picked Winners: {winPicksMark}<br />
                    AllTime Picked Losers: {losePicksMark}<br />
                    AllTime Picked Ties: {tiePicksMark}<br />
                    <div className="winnings">${winningsMark}</div>
                    </div>
            </div>

            <div className="playercontainer">
                    <div className="playerdiv">
                    <img src={picMac} alt="Player" className="playerPic" />
                    </div>
                    <div className="playerdivstats">
                    <span className="statbold">Player Stats:</span><br />
                    <span className="statbold">Bill {winPicksMac}-{losePicksMac}-{tiePicksMac}</span><br />
                    AllTime Pick Winner Percentage: {winPercentMac}%<br />
                    AllTime Picked Winners: {winPicksMac}<br />
                    AllTime Picked Losers: {losePicksMac}<br />
                    AllTime Picked Ties: {tiePicksMac}<br />
                    <div className="winnings">${winningsMac}</div>
                    </div>
            </div>
            <div className="clearall"></div>

            </div>
            </div>
            )
        }else{
            return( 
                <div className="content">
                        <h2>LifeTime Stats</h2>
                <div className="voice1">

                <div className="playercontainer">
                        <div className="playerdiv">
                        <img src={picMacW} alt="Player" className="playerPic" />
                        </div>
                        <div className="playerdivstats">
                        <span className="statbold">Player Stats:</span><br />
                        <span className="statbold">Bill {winPicksMac}-{losePicksMac}-{tiePicksMac}</span><br />
                        AllTime Pick Winner Percentage: {winPercentMac}%<br />
                        AllTime Picked Winners: {winPicksMac}<br />
                        AllTime Picked Losers: {losePicksMac}<br />
                        AllTime Picked Ties: {tiePicksMac}<br />
                        <div className="winnings">${winningsMac}</div>
                        </div>
                </div>
                
                <div className="playercontainer">
                        <div className="playerdiv">
                        <img src={picMarkL} alt="Player" className="playerPic" />
                        </div>
                        <div className="playerdivstats">
                        <span className="statbold">Player Stats:</span><br />
                        <span className="statbold">Mark {winPicksMark}-{losePicksMark}-{tiePicksMark}</span><br />
                        AllTime Pick Winner Percentage: {winPercentMark}%<br />
                        AllTime Picked Winners: {winPicksMark}<br />
                        AllTime Picked Losers: {losePicksMark}<br />
                        AllTime Picked Ties: {tiePicksMark}<br />
                        <div className="winnings">${winningsMark}</div>
                        </div>
                </div>
                <div className="clearall"></div>
    
                </div>
                </div>
            )
        }
}

export default AllTimeStats
